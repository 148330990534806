import { SetStateAction, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector, batch } from "react-redux";
import { listStores } from "./reducers/stores";
import {
  Account,
  APIError,
  Organisation,
  OrganisationCreateResponse,
  Store,
} from "./backendTypes";
import TUtils from "./TUtils";

import { RootState, useAppDispatch as useDispatch } from "./store";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { createApproval, listApprovals } from "./reducers/approvals";
import { createTable, HeadCell, Order } from "./tableUtils";
import { listAccounts } from "./reducers/accounts";
import {
  createOrganisation,
  listOrganisations,
} from "./reducers/organisations";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const headCells: HeadCell<Organisation & { customer_sites: Store[] }>[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Organisation-ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
];

const headerStyle = {
  margin: "0.5em",
  paddingLeft: "8px",
};

const buttonStyle = {
  paddingTop: "2em",
  paddingBottom: "1em",
  paddingLeft: "16px",
};

const searchBoxStyle = {
  paddingLeft: "16px",
};

const searchInputStyle = {
  paddingLeft: "8px",
};

function Organisations() {
  const scope = "kytron::read_events";
  const manageOrganisationsApprovalScope = "manage_organisations";
  const navigate = useNavigate();

  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );

  const session = useSelector((state: RootState) => state.user.session);
  const account = useSelector((state: RootState) => state.user.account);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations
  );
  const stores = useSelector((state: RootState) => state.stores.stores);
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const approvals = useSelector(
    (state: RootState) => state.approvals.approvals
  );

  const handleUpdate = () => {
    dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    dispatch(listStores({ scope, session, backendUrl }));
    dispatch(listAccounts({ session, backendUrl }));
    dispatch(listApprovals({ session, backendUrl }));
  };

  // component state
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Organisation>("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [organisationName, setOrganisationName] = useState("");
  const [clientNumber, setClientNumber] = useState("");
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
  const [accountInputValue, setAccountInputValue] = useState("");
  const [storeInputValue, setStoreInputValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const organisationsWithStores = useMemo(() => {
    const orgas = organisations?.map((organisation) => {
      const orgaStores = (organisation?.customer_sites
        .map((site) => {
          const store = stores?.find((el) => el.csid === site.id);
          return store;
        })
        .filter((el) => !!el?.csid) ?? []) as Store[];
      return { ...organisation, customer_sites: orgaStores } as Organisation & {
        customer_sites: Store[];
      };
    });
    return orgas;
  }, [organisations, stores]);

  const [rows, setRows] = useState(organisationsWithStores);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [addDlgOpen, setAddDlgOpen] = useState(false);

  useEffect(() => {
    if (searchText.length !== 0) {
      setRows(TUtils.filterArrayByString(organisationsWithStores, searchText));
      setPage(0);
    } else {
      setRows(organisationsWithStores);
    }
  }, [organisationsWithStores, searchText]);

  useEffect(() => {
    if (stores.length == 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
    if (accounts.length == 0) {
      dispatch(listAccounts({ session, backendUrl }));
    }
    if (organisations.length == 0) {
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    }
    if (approvals.length === 0) {
      dispatch(listApprovals({ session, backendUrl }));
    }
  }, []);

  const manageOrgasApproval = useMemo(
    () =>
      !!account?.scp.any?.find((el) => el === manageOrganisationsApprovalScope),
    [account]
  );

  const handleClickOpen = () => {
    setAddDlgOpen(true);
  };

  const handleClose = () => {
    setAddDlgOpen(false);
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Organisation
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (
    _event: React.MouseEvent<unknown>,
    id: string | boolean | number
  ) => {
    // console.log('Element clicked!');
    navigate(`/organisation/${id}`);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onNameInputChanged = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setOrganisationName(event.target.value);
  };
  const onClientNumberInputChanged = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setClientNumber(event.target.value);
  };

  const onSearchInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchText(event.target.value);
  };

  const rowToCell = (row: Organisation & { customer_sites: Store[] }) => {
    return (
      <TableRow
        hover
        key={row.id}
        sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
      >
        <TableCell>
          <Link
            to={`/organisation/${row.id}`}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "block",
            }}
          >
            {row.id}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={`/organisation/${row.id}`}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "block",
            }}
          >
            {row.name}
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  const handleAddAccountsToStores = () => {
    batch(() => {
      selectedStores.forEach((store) => {
        selectedAccounts.forEach((account) => {
          const approval = approvals.find(
            (appr) =>
              appr.scope === "kytron::read_events" &&
              appr.acid == account.acid &&
              appr.rsid === store.csid
          );
          if (!approval) {
            dispatch(
              createApproval({
                acid: account.acid,
                scope: "kytron::read_events",
                rsid: store.csid,
                rstype: 0,
                session,
                backendUrl,
              })
            );
          }
        });
      });
    });
  };

  const handleCreateOrganisation = () => {
    dispatch(
      createOrganisation({
        name: organisationName,
        client_number: clientNumber,
        customer_sites: selectedStores.map((el) => ({ id: el.csid })),
        accounts: selectedAccounts.map((el) => ({ id: el.acid })),
        session,
        backendUrl: chiefBackendUrl,
        feature_flags: {},
      })
    )
      .unwrap()
      .then((createResponse: OrganisationCreateResponse) => {
        handleAddAccountsToStores();
        setAddDlgOpen(false);
        setSelectedAccounts([]);
        setSelectedStores([]);
        setOrganisationName("");
        setClientNumber("");
        handleUpdate();
        setSuccessMessage(
          `Organisation '${organisationName}' created with id '${createResponse.id}'`
        );
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to create organisation with name '${organisationName}': ${err.error}`
        );
      });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <div>
      <div className="centered">
        <Box sx={{ width: "100%" }}>
          <Collapse in={successMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="success"
              onClose={() => {
                setSuccessMessage("");
              }}
            >
              {successMessage}
            </Alert>
          </Collapse>
          <Collapse in={errorMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="error"
              onClose={() => {
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              Existing organisations
            </Typography>
            <div className="hbox" style={searchBoxStyle}>
              <SearchIcon />
              <Input
                placeholder="Search"
                className="flex flex-1 mx-8"
                disableUnderline
                fullWidth
                value={searchText}
                inputProps={{
                  "aria-label": "Search",
                }}
                onChange={onSearchInputChange}
                style={searchInputStyle}
              />
            </div>
            {createTable<Organisation & { customer_sites: Store[] }>(
              headCells,
              order,
              orderBy,
              rows,
              emptyRows,
              page,
              rowsPerPage,
              rowToCell,
              handleRequestSort,
              handleChangePage,
              handleChangeRowsPerPage
            )}
            <Button
              aria-label="delete"
              onClick={handleUpdate}
              startIcon={<UpdateIcon />}
              style={buttonStyle}
              color="secondary"
            >
              Refresh
            </Button>
            {manageOrgasApproval ? (
              <Button
                aria-label="create"
                onClick={handleClickOpen}
                startIcon={<AddCircleOutlineIcon />}
                style={buttonStyle}
              >
                Add Organisation
              </Button>
            ) : null}
            <Dialog open={addDlgOpen} onClose={handleClose}>
              <DialogTitle>Add Organisation</DialogTitle>
              <DialogContent sx={{ minHeight: 550, width: 600 }}>
                <DialogContentText>
                  Please provide the corresponding organisation info here:
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  label="Company name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={organisationName}
                  onChange={onNameInputChanged}
                />
                <TextField
                  margin="dense"
                  id="clientNumber"
                  label="Client Number"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={clientNumber}
                  onChange={onClientNumberInputChanged}
                />
                <Autocomplete
                  disablePortal
                  fullWidth
                  id="combo-stores"
                  options={stores}
                  multiple
                  value={selectedStores}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Stores"
                      variant="standard"
                      fullWidth
                    />
                  )}
                  inputValue={storeInputValue}
                  onInputChange={(event, value, reason) => {
                    if (event && event.type === "blur") {
                      setStoreInputValue("");
                    } else if (reason !== "reset") {
                      setStoreInputValue(value);
                    }
                  }}
                  isOptionEqualToValue={(option, selection) => {
                    if (option.csid === selection.csid) return true;
                    return false;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.csid}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 4 }}
                        checked={selected}
                      />
                      {option.name} - {option.csid}
                    </li>
                  )}
                  disableCloseOnSelect={true}
                  // style={defaultStyle}
                  // value={selectedStore}
                  onChange={(_event, newInputValue) =>
                    setSelectedStores(newInputValue)
                  }
                />
                <Autocomplete
                  disablePortal
                  fullWidth
                  id="combo-accounts"
                  options={accounts}
                  isOptionEqualToValue={(option, selection) => {
                    return option.acid === selection.acid;
                  }}
                  multiple
                  getOptionLabel={(option) => option.email}
                  value={selectedAccounts}
                  disableCloseOnSelect={true}
                  inputValue={accountInputValue}
                  onInputChange={(event, value, reason) => {
                    if (event && event.type === "blur") {
                      setAccountInputValue("");
                    } else if (reason !== "reset") {
                      setAccountInputValue(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Accounts"
                      variant="standard"
                      fullWidth
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.acid}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 4 }}
                        checked={selected}
                      />
                      {option.email}
                    </li>
                  )}
                  // style={defaultStyle}
                  // value={selectedStore}
                  onChange={(_event, newInputValue) => {
                    setSelectedAccounts(newInputValue);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={handleCreateOrganisation}
                  disabled={organisationName.length < 4}
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default Organisations;
