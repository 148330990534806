import { SetStateAction, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { listStores, createStore } from "./reducers/stores";
import {
  Store,
  StoreCreateResponse,
  APIError,
  Organisation,
} from "./backendTypes";
import TUtils from "./TUtils";

import { RootState, useAppDispatch as useDispatch } from "./store";
import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { listApprovals } from "./reducers/approvals";
import { createTable, HeadCell, Order } from "./tableUtils";
import { listSiterefs } from "./reducers/siterefs";
import { listOrganisations } from "./reducers/organisations";

const headCells: HeadCell<Store>[] = [
  {
    id: "csid",
    numeric: false,
    disablePadding: false,
    label: "Store-ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "active",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "pg_idx",
    numeric: false,
    disablePadding: false,
    label: "Cluster",
  },
  {
    id: "url",
    numeric: false,
    disablePadding: false,
    label: "URL",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Store",
  },
];

const headerStyle = {
  margin: "0.5em",
  paddingLeft: "8px",
};

const buttonStyle = {
  paddingTop: "2em",
  paddingBottom: "1em",
  paddingLeft: "16px",
};

const searchBoxStyle = {
  paddingLeft: "16px",
};

const searchInputStyle = {
  paddingLeft: "8px",
};
const defaultStyle = {
  marginTop: "0.5em",
};

const getClusterIdx = (backendUrl: string) => {
  if (backendUrl === "https://hive.tracify.ai/v1/tracify/api") return -1;
  else return 0;
};

export const shopSystemOptions = [
  {
    label: "Shopify",
    value: "shopify",
  },
  {
    label: "Shopify Plus",
    value: "shopify_plus",
  },
  {
    label: "WooCommerce",
    value: "woocommerce",
  },
  {
    label: "Shopware 5",
    value: "shopware5",
  },
  {
    label: "Shopware 6",
    value: "shopware6",
  },
  {
    label: "Magento v2.4",
    value: "magento2_4",
  },
  {
    label: "Elopage",
    value: "elopage",
  },
  {
    label: "Salesforce Commerce Cloud",
    value: "salesforce_commerce_cloud",
  },
  { label: "Digistore24", value: "digistore24" },
  { label: "Custom", value: "custom" },
];

function Stores() {
  const scope = "kytron::read_events";
  const navigate = useNavigate();

  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );

  const clusterOptions = [
    { label: "Cluster 1", value: 0 },
    { label: "Cluster 2", value: 1 },
    { label: "Cluster 3", value: 2 },
    { label: "Cluster 4", value: 3 },
    { label: "Cluster 5", value: 4 },
  ];

  const backendPriorityCluster = backendUrl.includes("devhive.tracify.ai");

  const session = useSelector((state: RootState) => state.user.session);
  const stores = useSelector((state: RootState) => state.stores.stores);
  // console.log(rows);

  const handleUpdate = () => {
    dispatch(listStores({ scope, session, backendUrl }));
    dispatch(listApprovals({ session, backendUrl }));
    dispatch(listSiterefs({ session, backendUrl }));
  };

  // component state
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Store>("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [storeName, setStoreName] = useState("");
  const [storeUrl, setStoreUrl] = useState("");
  const [storeDescription, setStoreDescription] = useState("");
  const [clusterModifiedOptions, setClusterModifiedOptions] =
    useState<(typeof clusterOptions)[number][]>(clusterOptions);

  const [selectedCluster, setSelectedCluster] = useState<
    (typeof clusterOptions)[number] | null
  >(null);
  const [shopSystem, setShopSystem] = useState<
    (typeof shopSystemOptions)[number] | null
  >(null);

  const [organisationValue, setOrganisationValue] = useState<{
    label: string;
    value: string;
    clusters: Array<number>;
  } | null>(null);

  const [storeRefs, setStoreRefs] = useState("");
  const [googleStoreRefs, setGoogleStoreRefs] = useState("");
  const [tiktokStoreRefs, setTiktokStoreRefs] = useState("");
  const [pinterestStoreRefs, setPinterestStoreRefs] = useState("");

  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(stores);

  // const [successDialogOpen, setSuccessDialogOpen] = useState(true);
  // const [errorDialogOpen, setErrorDialogOpen] = useState(true);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [addDlgOpen, setAddDlgOpen] = useState(false);

  useEffect(() => {
    if (searchText.length !== 0) {
      setRows(TUtils.filterArrayByString(stores, searchText));
      setPage(0);
    } else {
      setRows(stores);
    }
  }, [stores, searchText]);

  useEffect(() => {
    if (stores.length == 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
  }, []);

  const handleClickOpen = () => {
    setAddDlgOpen(true);
  };

  const handleClose = () => {
    setAddDlgOpen(false);
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Store
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (
    _event: React.MouseEvent<unknown>,
    csid: string | boolean | number
  ) => {
    // console.log('Element clicked!');
    navigate(`/store/${csid}`);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onNameInputChanged = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setStoreName(event.target.value);
  };

  const onStoreUrlChanged = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    const url = TUtils.validateURL(event.target.value);
    setStoreUrl(url);
  };

  const onStoreDescriptionChanged = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setStoreDescription(event.target.value);
  };

  const onStoreRefsChanged = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setStoreRefs(event.target.value);
  };

  const onGoogleStoreRefsChanged = (event: { target: { value: string } }) => {
    const value = event.target.value.replace(/[^a-zA-Z0-9,]/g, "");
    setGoogleStoreRefs(value);
  };

  const onTiktokStoreRefsChanged = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setTiktokStoreRefs(event.target.value);
  };
  const onPinterestStoreRefsChanged = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setPinterestStoreRefs(event.target.value);
  };

  const onSearchInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchText(event.target.value);
  };

  const rowToCell = (row: Store) => {
    return (
      <TableRow hover key={row.csid}>
        <TableCell>
          <Link
            to={`/store/${row.csid}`}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "block",
            }}
          >
            {row.csid}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={`/store/${row.csid}`}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "block",
            }}
          >
            {row.name}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={`/store/${row.csid}`}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "block",
            }}
          >
            {row.active ? "Active" : "Inactive"}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={`/store/${row.csid}`}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "block",
            }}
          >
            {row.pg_idx + 1}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={`/store/${row.csid}`}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "block",
            }}
          >
            {row.url}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={`/store/${row.csid}`}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "block",
            }}
          >
            {row.description}
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  const toRefArray = (dt: string, rtype: string) => {
    return dt.split(",").map((x) => [x.trim(), rtype]);
  };

  const handleCreateStore = () => {
    let allRefs: string[][] = [];
    if (storeRefs) {
      const fbRefs = toRefArray(storeRefs, "fb");
      allRefs = allRefs.concat(fbRefs);
    }
    if (tiktokStoreRefs) {
      const tiktokRefs = toRefArray(tiktokStoreRefs, "tiktok");
      allRefs = allRefs.concat(tiktokRefs);
    }
    if (googleStoreRefs) {
      const googleRefs = toRefArray(googleStoreRefs, "google");
      allRefs = allRefs.concat(googleRefs);
    }
    if (pinterestStoreRefs) {
      const pinterestRefs = toRefArray(pinterestStoreRefs, "pinterest");
      allRefs = allRefs.concat(pinterestRefs);
    }

    dispatch(
      createStore({
        name: storeName,
        url: storeUrl,
        shop_system: shopSystem?.value ?? "",
        description: storeDescription,
        refs: Object.fromEntries(allRefs),
        pg_idx: selectedCluster?.value ?? getClusterIdx(backendUrl),
        redis_idx: selectedCluster?.value ?? getClusterIdx(backendUrl),
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: StoreCreateResponse) => {
        setAddDlgOpen(false);
        setGoogleStoreRefs("");
        setTiktokStoreRefs("");
        setPinterestStoreRefs("");
        setStoreRefs("");
        setStoreName("");
        setSelectedCluster(null);
        setOrganisationValue(null);
        setStoreDescription("");
        setStoreUrl("");
        handleUpdate();
        setSuccessMessage(
          `Store '${storeName}' created with csid '${createResponse.csid}'`
        );
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to create store with name '${storeName}': ${err.error}`
        );
      });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const organisations: Organisation[] = useSelector(
    (state: RootState) => state.organisations.organisations
  );

  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );
  useEffect(() => {
    if (organisations.length == 0) {
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    }
  }, []);

  // Generate organisations with clusters, based on stores. This is used to filter clusters based on the selected organisation.
  const organisationsWithClusters = useMemo(
    () =>
      organisations.reduce(
        (
          acc: Array<{
            label: string;
            value: string;
            clusters: Array<number>;
          }>,
          orga
        ) => {
          const orgaStores = stores?.filter((store) =>
            orga.customer_sites?.find((site) => site.id === store.csid)
          );

          const pgIdxs = orgaStores?.map((store) => store.pg_idx);

          return [
            ...acc,
            { label: orga.name, value: orga.id, clusters: pgIdxs },
          ];
        },
        []
      ),

    [organisations, stores]
  );

  // Update cluster options based on selected organisation (if any)
  useEffect(() => {
    const organisation = organisationValue
      ? organisationsWithClusters.find(
          (orga) => orga.value === organisationValue.value
        )
      : null;

    const clusters = organisation?.clusters || [];

    setClusterModifiedOptions(
      clusters.length
        ? clusterOptions.filter((cluster) => clusters.includes(cluster.value))
        : clusterOptions
    );
    setSelectedCluster(null);
  }, [organisationsWithClusters, organisationValue]);

  return (
    <div>
      <div className="centered">
        <Box sx={{ width: "100%" }}>
          <Collapse in={successMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="success"
              onClose={() => {
                setSuccessMessage("");
              }}
            >
              {successMessage}
            </Alert>
          </Collapse>
          <Collapse in={errorMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="error"
              onClose={() => {
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              Existing stores
            </Typography>
            <div className="hbox" style={searchBoxStyle}>
              <SearchIcon />
              <Input
                placeholder="Search"
                className="flex flex-1 mx-8"
                disableUnderline
                fullWidth
                value={searchText}
                inputProps={{
                  "aria-label": "Search",
                }}
                onChange={onSearchInputChange}
                style={searchInputStyle}
              />
            </div>
            {createTable<Store>(
              headCells,
              order,
              orderBy,
              rows,
              emptyRows,
              page,
              rowsPerPage,
              rowToCell,
              handleRequestSort,
              handleChangePage,
              handleChangeRowsPerPage
            )}
            <Button
              aria-label="delete"
              onClick={handleUpdate}
              startIcon={<UpdateIcon />}
              style={buttonStyle}
              color="secondary"
            >
              Refresh
            </Button>
            <Button
              aria-label="create"
              onClick={handleClickOpen}
              startIcon={<AddCircleOutlineIcon />}
              style={buttonStyle}
            >
              Add Store
            </Button>
            <Dialog open={addDlgOpen} onClose={handleClose}>
              <DialogTitle>Add Store</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please provider the corresponding store info here:
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  label="Company name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={storeName}
                  onChange={onNameInputChanged}
                />
                <TextField
                  required
                  margin="dense"
                  id="url"
                  label="URL"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={storeUrl}
                  onChange={onStoreUrlChanged}
                />
                <TextField
                  required
                  margin="dense"
                  id="description"
                  label="Store name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={storeDescription}
                  onChange={onStoreDescriptionChanged}
                />
                <Autocomplete
                  disablePortal
                  id="organisations"
                  options={organisationsWithClusters}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, selection) => {
                    return option.value === selection?.value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organisation"
                      variant="standard"
                    />
                  )}
                  style={defaultStyle}
                  value={organisationValue}
                  onChange={(_event: any, newValue: any) =>
                    setOrganisationValue(newValue)
                  }
                />

                <Autocomplete
                  disablePortal
                  id="shop-system"
                  options={shopSystemOptions}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, selection) => {
                    return option.value === selection?.value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Shop System"
                      variant="standard"
                    />
                  )}
                  style={defaultStyle}
                  value={shopSystem}
                  onChange={(_event: any, newValue: any) =>
                    setShopSystem(newValue)
                  }
                />
                <Autocomplete
                  disablePortal
                  id="cluster-index"
                  options={
                    backendPriorityCluster
                      ? [
                          {
                            label: "Cluster 1",
                            value: 0,
                          },
                        ]
                      : clusterModifiedOptions
                  }
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, selection) => {
                    return option.value === selection?.value;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Cluster" variant="standard" />
                  )}
                  style={defaultStyle}
                  value={selectedCluster}
                  onChange={(_event: any, newValue: any) =>
                    setSelectedCluster(newValue)
                  }
                />
                <TextField
                  margin="dense"
                  id="fbids"
                  label="Facebook Account IDs"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={storeRefs}
                  onChange={onStoreRefsChanged}
                />
                <TextField
                  margin="dense"
                  id="grefids"
                  label="Google Account IDs"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={googleStoreRefs}
                  onChange={onGoogleStoreRefsChanged}
                />
                <TextField
                  margin="dense"
                  id="ttrefids"
                  label="Tiktok Account IDs"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={tiktokStoreRefs}
                  onChange={onTiktokStoreRefsChanged}
                />
                <TextField
                  margin="dense"
                  id="prefids"
                  label="Pinterest Account IDs"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={pinterestStoreRefs}
                  onChange={onPinterestStoreRefsChanged}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={handleCreateStore}
                  disabled={storeName.length < 4}
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default Stores;
