import {
  Box,
  Paper,
  Typography,
  TableCell,
  TableRow,
  Alert,
  Collapse,
  Input,
  Button,
} from "@mui/material";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { listStores } from "./reducers/stores";
import { RootState, useAppDispatch as useDispatch } from "./store";
import TUtils from "./TUtils";
import UpdateIcon from "@mui/icons-material/Update";

import SearchIcon from "@mui/icons-material/Search";
import { AdAccount } from "./backendTypes";
import { createTable, HeadCell, Order } from "./tableUtils";
import { listAdAccounts } from "./reducers/ad-accounts";
import { DateTime } from "luxon";
import { CheckCircleOutline, ErrorOutlineSharp } from "@mui/icons-material";
import { listSiterefs } from "./reducers/siterefs";
import { listOrganisations } from "./reducers/organisations";
import { Link, useNavigate } from "react-router-dom";

const headerStyle = {
  paddingTop: "2em",
  paddingBottom: "1em",
  paddingLeft: "22px",
};

const buttonStyle = {
  paddingTop: "2em",
  paddingBottom: "1em",
  paddingLeft: "16px",
};

const searchBoxStyle = {
  paddingLeft: "16px",
};

const searchInputStyle = {
  paddingLeft: "8px",
};

const adAccountHeadCells: HeadCell<
  AdAccount & {
    storeName: string;
    csid: string;
    organisation: string;
    organisationId: string;
  }
>[] = [
  {
    id: "organisation",
    numeric: false,
    disablePadding: false,
    label: "Organisation",
  },
  {
    id: "storeName",
    numeric: false,
    disablePadding: false,
    label: "Store",
  },
  {
    id: "csid",
    numeric: false,
    disablePadding: false,
    label: "CSID",
  },
  {
    id: "source",
    numeric: false,
    disablePadding: false,
    label: "Reference Type",
  },
  {
    id: "account_id",
    numeric: false,
    disablePadding: false,
    label: "Reference-ID",
  },
  {
    id: "last_scrape_date",
    numeric: false,
    disablePadding: false,
    label: "Last Scrape",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

function SyncingPage() {
  const scope = "kytron::read_events";
  const navigate = useNavigate();
  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const adconnectorBackendUrl = useSelector(
    (state: RootState) => state.environment.adconnectorBackendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );

  const session = useSelector((state: RootState) => state.user.session);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations
  );
  const stores = useSelector((state: RootState) => state.stores.stores);
  const adAccountsState = useSelector(
    (state: RootState) => state.adAccounts.adAccounts
  );
  const siterefs = useSelector((state: RootState) => state.siterefs.siterefs);
  const handleUpdate = () => {
    dispatch(listStores({ scope, session, backendUrl }));
    dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    dispatch(listSiterefs({ session, backendUrl }));
    dispatch(listAdAccounts({ session, backendUrl: adconnectorBackendUrl }));
  };
  useEffect(() => {
    if (stores.length == 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
    if (organisations.length == 0) {
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    }
    if (siterefs.length == 0) {
      dispatch(listSiterefs({ session, backendUrl }));
    }
    if (adAccountsState?.length == 0) {
      dispatch(listAdAccounts({ session, backendUrl: adconnectorBackendUrl }));
    }
  }, []);

  const adAccounts: (AdAccount & {
    storeName: string;
    csid: string;
    organisation: string;
    organisationId: string;
  })[] = useMemo(() => {
    if (
      adAccountsState.length === 0 ||
      siterefs.length === 0 ||
      stores.length === 0
    ) {
      return [] as (AdAccount & {
        storeName: string;
        csid: string;
        organisation: string;
        organisationId: string;
      })[];
    }
    const accounts: (AdAccount & {
      storeName: string;
      csid: string;
      organisation: string;
      organisationId: string;
    })[] = [];
    for (const adAccount of adAccountsState) {
      const adAccountCopy = structuredClone(adAccount) as AdAccount & {
        storeName: string;
        csid: string;
        organisation: string;
        organisationId: string;
      };
      const siteRef = siterefs.find(
        (siteref) => siteref.refid === adAccount.account_id
      );
      if (siteRef) {
        const store = stores.find((el) => el.csid === siteRef.csid);
        if (store) {
          const organisation = organisations.find(
            (el) =>
              el.customer_sites.findIndex((cs) => cs.id === store.csid) !== -1
          );

          adAccountCopy.storeName = store.description;
          adAccountCopy.organisation = organisation?.name ?? "";
          adAccountCopy.organisationId = organisation?.id ?? "";
          adAccountCopy.csid = store.csid;
          if (store.active) {
            accounts.push(adAccountCopy);
          }
        }
      }
    }

    return accounts;
  }, [adAccountsState, siterefs, stores]);

  const [adAccountRows, setAdAccountRows] = useState<
    Array<
      AdAccount & {
        storeName: string;
        csid: string;
        organisation: string;
        organisationId: string;
      }
    >
  >([]);

  const [adAccountOrder, setAdAccountOrder] = useState<Order>("asc");
  const [adAccountOrderBy, setAdAccountOrderBy] = useState<
    keyof (AdAccount & {
      storeName: string;
      csid: string;
      organisation: string;
      organisationId: string;
    })
  >("last_scrape_date");
  const [adAccountsPage, setAdAccountPage] = useState(0);
  const [adAccountRowsPerPage, setAdAccountRowsPerPage] = useState(10);
  // const [selectedStore, setSelectedStore] = useState<Store | null>();
  const [errorMessage, setErrorMessage] = useState("");

  const [adAccountSearchText, setAdAccountSearchText] = useState("");

  useEffect(() => {
    if (adAccountSearchText.length !== 0) {
      const filteredAdAccounts = TUtils.filterArrayByString(
        adAccounts,
        adAccountSearchText
      );
      setAdAccountRows(filteredAdAccounts);
      setAdAccountPage(0);
    } else {
      setAdAccountRows(adAccounts);
    }
  }, [adAccounts, adAccountSearchText]);

  const handleSyncRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof (AdAccount & {
      storeName: string;
      csid: string;
      organisation: string;
      organisationId: string;
    })
  ) => {
    const isAsc = adAccountOrderBy === property && adAccountOrder === "asc";
    setAdAccountOrder(isAsc ? "desc" : "asc");
    setAdAccountOrderBy(property);
  };

  const handleSyncChangePage = (_event: unknown, newPage: number) => {
    setAdAccountPage(newPage);
  };

  const handleSyncChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdAccountRowsPerPage(parseInt(event.target.value, 10));
    setAdAccountPage(0);
  };
  const syncToCell = (
    row: AdAccount & {
      storeName: string;
      csid: string;
      organisation: string;
      organisationId: string;
    }
  ) => {
    return (
      <TableRow hover key={row.account_id}>
        <TableCell>
          <Link
            to={
              row?.organisationId ? `/organisation/${row.organisationId}` : "#"
            }
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {row.organisation}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={`/store/${row.csid}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {row.storeName}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={`/store/${row.csid}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {row.csid}
          </Link>
        </TableCell>
        <TableCell>{row.source}</TableCell>
        <TableCell>{row.account_id}</TableCell>
        <TableCell>
          {DateTime.fromISO(row.last_scrape_date)?.isValid
            ? DateTime.fromISO(row.last_scrape_date).toFormat(
                "yyyy-MM-dd HH:mm:ss"
              )
            : "-"}
        </TableCell>
        <TableCell>
          {row.status === "error" ? (
            <ErrorOutlineSharp color="error" />
          ) : (
            <CheckCircleOutline color="success" />
          )}
        </TableCell>
      </TableRow>
    );
  };
  const emptyAdAccountRows =
    adAccountsPage > 0
      ? Math.max(
          0,
          (1 + adAccountsPage) * adAccountRowsPerPage - adAccountRows.length
        )
      : 0;

  return (
    <div>
      <div className="centeredn">
        <Box sx={{ width: "100%" }}>
          <Collapse in={errorMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="error"
              onClose={() => {
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              Sync History
            </Typography>
            <div className="hbox" style={searchBoxStyle}>
              <SearchIcon />
              <Input
                placeholder="Search"
                className="flex flex-1 mx-8"
                disableUnderline
                fullWidth
                value={adAccountSearchText}
                inputProps={{
                  "aria-label": "Search",
                }}
                onChange={(event: {
                  target: { value: SetStateAction<string> };
                }) => setAdAccountSearchText(event.target.value)}
                style={searchInputStyle}
              />
            </div>
            {createTable<
              AdAccount & {
                storeName: string;
                csid: string;
                organisation: string;
                organisationId: string;
              }
            >(
              adAccountHeadCells,
              adAccountOrder,
              adAccountOrderBy,
              adAccountRows,
              emptyAdAccountRows,
              adAccountsPage,
              adAccountRowsPerPage,
              syncToCell,
              handleSyncRequestSort,
              handleSyncChangePage,
              handleSyncChangeRowsPerPage
            )}

            <Button
              aria-label="delete"
              onClick={handleUpdate}
              startIcon={<UpdateIcon />}
              style={buttonStyle}
              color="secondary"
            >
              Refresh
            </Button>
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default SyncingPage;
