import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Login from "./Login";
import AppBar from "./AppBar";
import Accounts from "./Accounts";
import Stores from "./ClientStores";
import Store from "./ClientStore";
import Status from "./Status";
import Account from "./Account";
import OrderStatus from "./OrderStatus";
import Organisations from "./Organisations";
import Organisation from "./Organisation";
import SyncingPage from "./SyncingPage";
import ScrollToTop from "ScrollToTop";
import { checkUserAccount } from "./reducers/user";
import { RootState, useAppDispatch as useDispatch } from "./store";

const LoadingSpinner = () => (
  <div
    style={{
      display: "flex",
      width: "100%",
      height: "100%",
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}
  >
    <img
      src={"icon.png"}
      height={48}
      width={48}
      alt="Tracify Icon"
      style={{ marginBottom: 8 }}
    />
    <span style={{ display: "flex", alignItems: "center" }}>
      <CircularProgress size={24} color="inherit" /> &nbsp;
      <p> Loading Theresa App</p>
    </span>
  </div>
);

type ProtectedRouteProps = {
  element: React.ReactElement;
  isAuthenticated: boolean;
};

const ProtectedRoute = ({ element, isAuthenticated }: ProtectedRouteProps) => {
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  return element;
};

function Routing() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const account = useSelector((state: RootState) => state.user.account);
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await dispatch(checkUserAccount({ backendUrl }));
      } finally {
        setTimeout(() => setIsLoading(false), 500);
      }
    };

    initializeApp();
  }, [dispatch, backendUrl]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {account && <AppBar />}
      <ScrollToTop />
      <Routes>
        <Route
          path="/login"
          element={account ? <Navigate to="/" replace /> : <Login />}
        />

        <Route
          path="/"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              element={<Organisations />}
            />
          }
        />
        <Route
          path="/organisations"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              element={<Organisations />}
            />
          }
        />
        <Route
          path="/organisation/:id"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              element={<Organisation />}
            />
          }
        />
        <Route
          path="/accounts"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              element={<Accounts />}
            />
          }
        />
        <Route
          path="/account/:acid"
          element={
            <ProtectedRoute isAuthenticated={!!account} element={<Account />} />
          }
        />
        <Route
          path="/stores"
          element={
            <ProtectedRoute isAuthenticated={!!account} element={<Stores />} />
          }
        />
        <Route
          path="/store/:csid"
          element={
            <ProtectedRoute isAuthenticated={!!account} element={<Store />} />
          }
        />
        <Route
          path="/status"
          element={
            <ProtectedRoute isAuthenticated={!!account} element={<Status />} />
          }
        />
        <Route
          path="/syncing"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              element={<SyncingPage />}
            />
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute
              isAuthenticated={!!account}
              element={<OrderStatus />}
            />
          }
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default Routing;
